<template>
	<div :style="authBg">
		<Header :title="$t('setting.information')"></Header>
		
		<div class="column-center-content main-content">
			<div class="form-content">
				<label class="form-title">{{$t('common.userName')}}</label>
				<v-text-field color="success" style="width: 100%; padding-top: 5px;" v-model="userName" readonly></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('setting.userStatus')}}</label>				
				<v-text-field color="success" v-if="status === 1" :value="$t('setting.noVerify')"  style="width: 100%; padding-top: 5px;" readonly></v-text-field>
				<v-text-field color="success" v-if="status === 2" :value="$t('setting.hasVerify')"  style="width: 100%; padding-top: 5px;" readonly></v-text-field>
				<v-text-field color="success" v-if="status === 3" :value="$t('setting.errorVerify')"  style="width: 100%; padding-top: 5px;" readonly></v-text-field>
				<v-text-field color="success" v-if="status === 4" :value="$t('setting.inVerify')"  style="width: 100%; padding-top: 5px;" readonly></v-text-field>
			</div>
			
			<router-link to="/auth" v-if="status === 1" class="btn-content">
				<v-btn rounded block style="background-color: #009245; color: white; height: 40px;">
					{{$t('setting.goVerify')}}
				</v-btn>
			</router-link>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../components/NavHeader.vue'
	
	export default {
		name: 'BuyHkForSelf',
		components: {
			Header
		},
		data() { 
			return {
				authBg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				userName: JSON.parse(localStorage.getItem('userInfo')).username,
				status: JSON.parse(localStorage.getItem('userInfo')).state
			}
		},
		mounted() {
			let status = this.$data.status;
			if(status === 1) {
				// 未实名认证，请求接口（0未认证，>0已提交）
				this.$request.kycStatus({}).then((data) => {
					console.log(data.length);
					if(data.length > 0) {
						this.$data.status = 4;
					}
				});
			}
			
		}
	}
</script>

<style>
</style>
